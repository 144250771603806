import * as yup from 'yup'
import isEmpty from 'validator/lib/isEmpty'

export const loginSchema = yup.object().shape({
  email: yup.string().required('Este campo es obligatorio'),
  password: yup
    .string()
    .min(3, 'La contraseña debe tener minimo 3 caracteres')
    .required('Este campo es obligatorio'),
  IdEmpresa: yup.number().min(1, 'Seleciona una empresa')
})

export const loginValidation = ({ email, password, IdEmpresa }) => {
  const errors = {}
  if (IdEmpresa < 1) {
    errors.IdEmpresa = 'Seleciona una empresa'
  }
  if (isEmpty(email)) {
    errors.email = 'Este campo es obligatorio'
  }
  if (password.length < 3) {
    errors.password = 'La contraseña debe tener minimo 3 caracteres'
  }
  if (isEmpty(password)) {
    errors.password = 'Este campo es obligatorio'
  }
  return errors
}
