import React from 'react'
import { BookOpen, Edit } from 'react-feather'

const DespachoActions = ({
  estado,
  redirectToEditarGuia,
  redirectToLeerGuia,
  openModalDelete
}) => {
  const renderBookOpen = () => (
    <span onClick={redirectToLeerGuia}>
      <BookOpen className="w-5 h-5 cursor-pointer transform hover:scale-105" />
    </span>
  )

  const renderActions = () => (
    <>
      <span onClick={redirectToEditarGuia}>
        <Edit className="w-5 h-5 cursor-pointer transform hover:scale-105" />
      </span>
      {/* <span onClick={openModalDelete}>
        <Trash className="w-5 h-5 cursor-pointer transform hover:scale-105" />
      </span> */}
    </>
  )

  return (
    <div className="flex items-center justify-around">
      {estado === 'ENTREGADO' ? renderBookOpen() : renderActions()}
    </div>
  )
}

export default DespachoActions
