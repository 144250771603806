import { useContext } from 'react'
import { AuthContext } from '../context/states/AuthState'

const useAuth = () => {
  const state = useContext(AuthContext)
  return { ...state }
  // return { user, isAuth, LoginAction, LogoutAction }
}

export default useAuth
