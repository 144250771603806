import { gql } from '@apollo/client'

export const CREATE_GUIA_NUEVA_MUTATION = gql`
  mutation createGuiasCliente($input: GuiasClienteInput!, $ImagenGuia: Upload) {
    createGuiasCliente(input: $input, ImagenGuia: $ImagenGuia) {
      IdGuiaR
      Serie
      Numero
      Fecha
      Llegada
      Bultos
      Peso
      FechaEntrega
      Coordenadas
      ImagenGuia
      SerieGT
      NumeroGT
      IdServicio
      IdCliente
      IdDestino
      IdEstado
    }
  }
`
