import { gql } from '@apollo/client'

export const GET_BUSQUEDA_AVANZADA = gql`
  query GetBusquedaAvanzadaListaGuias(
    $number_paginate: Int
    $page: Int
    $IdCliente: Int
    $Numero: String
    $IdEstado: Int
    $Fecha: String
    $Placa1: String
    $IdEmpresa: Int
  ) {
    GetBusquedaAvanzadaListaGuias(
      number_paginate: $number_paginate
      page: $page
      IdCliente: $IdCliente
      Numero: $Numero
      IdEstado: $IdEstado
      Fecha: $Fecha
      Placa1: $Placa1
      IdEmpresa: $IdEmpresa
    ) {
      nroTotal_items
      data {
        IdGuiaR
        IdServicio
        IdCliente
        Serie
        Numero
        Fecha
        Cliente
        Destinatario
        Llegada
        Bultos
        Peso
        SerieGT
        NumeroGT
        NmEstado
        FechaEntrega
        Coordenadas
        ImagenGuia
        IdDestino
        IdEstado
        Placa1
      }
    }
  }
`
