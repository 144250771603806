import { ChevronLeft } from 'react-feather'
import { useHistory, useParams } from 'react-router'
import FormularioGuia from 'src/components/formularioGuia/FormularioGuia'

// Apollo
import { useMutation } from '@apollo/client'
import { EDITAR_GUIA_MUTATION } from 'src/graphql/mutations/editarGuia_mutation'
import Loader from 'src/components/Loader/Loader'

const EditarDespacho = () => {
  const history = useHistory()
  const { id } = useParams()
  const guia = history.location.state

  const [editarGuiaMutation, { data, loading }] = useMutation(
    EDITAR_GUIA_MUTATION,
    {
      onError: (err) => {
        const error = err?.graphQLErrors[0]?.debugMessage
        if (error) {
          console.log(error)
        } else {
          console.log('error desconocido')
        }
      }
    }
  )
  console.log(data)

  // Funcion para editar guia
  const editarGuia = async (values) => {
    console.log('editar valores : ', values)
    const response = await editarGuiaMutation({
      variables: {
        input: {
          ...values
        }
      }
    })
    const idGuiaEditada = response.data.updateGuiasCliente.IdGuiaR
    if (idGuiaEditada) {
      history.push(`/lista-despacho/${guia.IdServicio}`)
    } else {
      console.log('Error al crear la guía')
    }
  }

  const formValues = {
    IdGuiaR: guia.IdGuiaR,
    IdServicio: guia.IdServicio,
    IdCliente: guia.IdCliente,
    Serie: guia.Serie,
    Numero: guia.Numero,
    Fecha: guia.Fecha,
    Llegada: guia.Llegada,
    Bultos: guia.Bultos,
    Peso: guia.Peso,
    SerieGT: guia.SerieGT,
    NumeroGT: guia.NumeroGT,
    FechaEntrega: guia.FechaEntrega.split(' ')[0],
    Coordenadas: guia.Coordenadas,
    IdDestino: guia.IdDestino,
    IdEstado: guia.IdEstado
  }

  const formProps = {
    mutationApi: editarGuia,
    loadingMutationGuia: loading,
    formValues: formValues,
    nameCliente: guia.Cliente
  }

  return (
    <div className="md:shadow md:rounded bg-white p-10">
      <h1 className="flex items-center text-xl md:text-2xl font-regular tracking-wide mb-5">
        <span
          onClick={() => history.goBack()}
          className="p-1 mr-3 rounded-full border border-blue-600 text-blue-600 cursor-pointer"
        >
          <ChevronLeft className="w-5 h-5" />
        </span>
        EDITAR GUIA {id}
      </h1>
      {loading ? <Loader /> : <FormularioGuia {...formProps} />}
    </div>
  )
}

export default EditarDespacho
