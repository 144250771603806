import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import { GET_LISTA_SERVICIOS } from 'src/graphql/querys/getListaDeServicios'
import TableServicios from 'src/components/TableServicios'
import useAuth from '../../hooks/useAuth'
import Pagination from 'src/components/paginacion/Pagination'
import Loader from 'src/components/Loader/Loader'

const ListaServicios = () => {
  const {
    user: { IdChofer }
  } = useAuth()

  const [paginaActual, setPaginaActual] = useState(1)
  const idEmpresa = parseInt(localStorage.getItem('idEmpresa'))
  // console.log(idEmpresa)

  const { data, loading } = useQuery(GET_LISTA_SERVICIOS, {
    fetchPolicy: 'network-only',
    variables: {
      number_paginate: 10,
      page: paginaActual,
      IdChofer: IdChofer,
      IdEmpresa: idEmpresa
    },
    onError: (err) => {
      const error = err?.graphQLErrors[0]?.debugMessage
      if (error) {
        console.log(error)
      } else {
        console.log('error desconocido')
      }
    }
  })

  console.log('loading servicios : ', loading)

  const nroPaginas = Math.ceil(data?.GetListaServicos?.nroTotal_items / 157)

  const listaDeServicios = data ? data.GetListaServicos.data : []

  const tableProps = {
    listaDeServicios: listaDeServicios
  }

  return (
    <div className="md:shadow md:rounded bg-white p-5 py-10 md:p-10">
      <h1 className="flex items-center text-xl md:text-2xl font-regular tracking-wide mb-5">
        LISTA DE SERVICIOS
      </h1>

      {/* TABLA */}
      <div className="overflow-x-auto w-full">
        {loading ? <Loader /> : <TableServicios {...tableProps} />}
      </div>

      {/* paginaActual */}

      {!loading && (
        <Pagination
          nroPaginas={nroPaginas}
          paginaActual={paginaActual}
          setPaginaActual={setPaginaActual}
        />
      )}
    </div>
  )
}

export default ListaServicios
