import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { PencilIcon } from 'src/assets/logo/icons'
import DespachoActions from '../despachoActions'
import ItemEstado from '../itemEstado/ItemEstado'
import ModalDeleteGuia from '../modalEliminarGuia/ModalEliminarGuia'
import ModalEstadoEntrega from '../modalEstadoEntrega'

const TableGuias = ({ listaDeGuias, refresPage }) => {
  const [openModal, setOpenModal] = useState(false)
  const [openModalDelete, setOpenModalDelete] = useState(false)
  const resetItemModal = () => setItemModal(null)
  const onToggle = () => {
    setOpenModal((prevState) => !prevState)
    openModal === true && resetItemModal()
  }

  const onToggleModalDelete = () => {
    setOpenModalDelete((prevState) => !prevState)
    openModalDelete === true && resetItemModal()
  }

  const [itemModal, setItemModal] = useState(null)

  const handleItemModal = (objGuia) => {
    setItemModal(objGuia)
    onToggle()
  }

  const handleItemModalDelete = (objGuia) => {
    setItemModal(objGuia)
    onToggleModalDelete()
  }

  const history = useHistory()
  const redirectToEditarGuia = (idGuia, objGuia) =>
    history.push(`/editar-despacho/${idGuia}`, objGuia)

  const redirectToLeerGuia = (idGuia, objGuia) =>
    history.push(`/leer-guia/${idGuia}`, objGuia)

  return (
    <>
      <ModalEstadoEntrega
        refresPage={refresPage}
        isOpen={openModal}
        onToggle={onToggle}
        itemModal={itemModal}
        onChangeModal={(file) => {}}
      />

      <ModalDeleteGuia
        refresPage={refresPage}
        isOpen={openModalDelete}
        onToggle={onToggleModalDelete}
        itemModal={itemModal}
        onChangeModal={(file) => {}}
      />

      <table className="min-w-max w-full table-auto ">
        <thead>
          <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
            <th className="py-3 pl-3 text-left">Id Guia</th>
            <th className="py-3 px-6 text-left">Fecha</th>
            <th className="py-3 px-3 text-center">Cliente</th>
            <th className="py-3 px-3 text-center">Destinatario</th>
            <th className="py-3 px-3 text-center">Llegada</th>
            <th className="py-3 px-6 text-center">Estado</th>
            <th className="py-3 px-6 text-center">Acciones</th>
          </tr>
        </thead>
        <tbody className="text-gray-600 text-sm font-light">
          {listaDeGuias.map((guia) => {
            return (
              <tr
                key={guia.IdGuiaR}
                className="border-b border-gray-200 hover:bg-gray-100"
              >
                <td className="py-3 pl-3 text-left whitespace-nowrap">
                  <div className="flex items-center">
                    <span className="font-medium">{guia.IdGuiaR}</span>
                  </div>
                </td>
                <td className="py-3 px-6 text-left">
                  <div className="flex items-center">
                    <span>{guia.Fecha}</span>
                  </div>
                </td>
                <td className="py-3 text-center">
                  <span>{guia.Cliente}</span>
                </td>
                <td className="py-3 text-center">
                  <span>{guia.Destinatario}</span>
                </td>
                <td className="py-3 px-3 text-center">
                  <span>{guia.Llegada}</span>
                </td>
                <td className="py-3 px-6 text-center">
                  <span className="flex gap-3">
                    <ItemEstado label={guia.NmEstado} type={guia.NmEstado} />
                    {guia.IdEstado !== 3 && (
                      <span
                        onClick={() => handleItemModal(guia)}
                        className="cursor-pointer"
                      >
                        <PencilIcon width="18" heigth="18" />
                      </span>
                    )}
                  </span>
                </td>
                <td className="py-3 px-6 text-center">
                  <DespachoActions
                    estado={guia.NmEstado}
                    redirectToLeerGuia={() => {
                      redirectToLeerGuia(guia.IdGuiaR, guia)
                    }}
                    redirectToEditarGuia={() =>
                      redirectToEditarGuia(guia.IdGuiaR, guia)
                    }
                    openModalDelete={() => handleItemModalDelete(guia)}
                  />
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </>
  )
}

export default TableGuias
