import { gql } from '@apollo/client'

export const LOGIN_MUTATION = gql`
  mutation login($input: UserInput!) {
    login(input: $input) {
      IdUsuario
      NmUsuario
      IdPerfil
      IdChofer
      IdCliente
      apiToken
    }
  }
`
