import React, { useState } from 'react'
import { useFormik } from 'formik'
import Modal from '../modal/Modal'
import Select from '@/components/Select'
import InputText from '@/components/InputText'
import ErrorMessage from '@/components/ErrorMessage'
import { useMutation, useQuery } from '@apollo/client'
import { GET_ESTADOS } from 'src/graphql/querys/getEstados'
import { EDITAR_GUIA_MUTATION } from 'src/graphql/mutations/editarGuia_mutation'
import { CREATE_GUIA_TRACK_MUTATION } from 'src/graphql/mutations/createGuiaTrack_mutation'
import Loader from '../Loader/Loader'
// import ItemEstado from '../itemEstado/ItemEstado'
import { getLocation } from '../../utils/geolocation'

const optionEstado = (objEstado) => (
  <option key={objEstado.IdEstado} value={objEstado.IdEstado}>
    {objEstado.NmEstado}
  </option>
)

// Botones del modal
const buttonsModalEstado = (onToggle, getLocation) => (
  <div className="flex justify-around">
    <button
      type="button"
      onClick={onToggle}
      className="w-full md:max-w-max mt-4 btn-outline-primary px-4"
    >
      Cancelar
    </button>
    <button type="submit" className="w-full md:max-w-max mt-4 btn-primary px-4">
      Modifcar
    </button>
  </div>
)

// Componente principal
const ModalEstadoEntrega = ({
  isOpen = false,
  onToggle = () => {},
  itemModal,
  refresPage
}) => {
  const [fileImg, setFileImg] = useState(null)

  const initialValues = {
    IdEstado: itemModal?.IdEstado,
    Obs: ''
  }
  // Consumir estados generales
  const { data } = useQuery(GET_ESTADOS, {
    variables: {},
    onError: (err) => {
      const error = err?.graphQLErrors[0]?.debugMessage
      if (error) {
        console.log(error)
      } else {
        console.log('error desconocido')
      }
    }
  })
  const estados = data ? data.GetEstados : []

  // CREAR TRACK MUTATION
  const [crearGuiaTrack] = useMutation(CREATE_GUIA_TRACK_MUTATION, {
    onError: (err) => {
      const error = err?.graphQLErrors[0]?.debugMessage
      if (error) {
        console.log(error)
      } else {
        console.log('error desconocido')
      }
    }
  })
  const useCrearGuiaTrackMutation = async (values) => {
    const location = await getLocation().catch(console.log)
    if (!location) {
      console.log('location', location)
      return
    }
    const response = await crearGuiaTrack({
      variables: {
        input: {
          IdGuiaR: values.IdGuiaR,
          FechaHora: new Date().toISOString(),
          Coordenadas: `${location.lat},${location.long}`,
          Obs: formik.values.Obs,
          IdEstado: values.IdEstado
        }
      }
    })
    console.log('response crear guia track : ', response.data.createGuiasTrack)
  }

  // EDITAR GUIA MUTATION
  const [editarGuiaMutation, { loading }] = useMutation(EDITAR_GUIA_MUTATION, {
    onError: (err) => {
      const error = err?.graphQLErrors[0]?.debugMessage
      if (error) {
        console.log(error)
      } else {
        console.log('error desconocido')
      }
    }
  })

  const formik = useFormik({
    initialValues,
    onSubmit: async (values) => {
      const location = await getLocation().catch(console.log)
      if (!location) {
        console.log('location', location)
        return
      }
      const payload = {
        variables: {
          input: {
            IdGuiaR: itemModal.IdGuiaR,
            Serie: itemModal.Serie,
            Numero: itemModal.Numero,
            Fecha: itemModal.Fecha,
            Llegada: itemModal.Llegada,
            Bultos: itemModal.Bultos,
            Peso: itemModal.Peso,
            FechaEntrega: itemModal.FechaEntrega,
            Coordenadas: `${location.lat},${location.long}`,
            SerieGT: itemModal.SerieGT,
            NumeroGT: itemModal.NumeroGT,
            IdServicio: itemModal.IdServicio,
            IdCliente: itemModal.IdCliente,
            IdDestino: itemModal.IdDestino,
            IdEstado: values.IdEstado
          }
        }
      }
      if (+values.IdEstado === 3) {
        const nameImage = `guia-${itemModal.IdGuiaR}.${
          fileImg.type.split('/')[1]
        }`
        const myNewFile = new File([fileImg], nameImage, { type: fileImg.type })
        payload.variables.ImagenGuia = myNewFile
      }
      // Primero editamos la guia
      const response = await editarGuiaMutation({
        ...payload,
        IdEstado: values.IdEstado
      })
      // Con la guia editada, procedemos a crear una guiaTrack
      const guiaEditada = response.data.updateGuiasCliente
      if (guiaEditada) {
        console.log('guia creada con exito: ', guiaEditada)
        useCrearGuiaTrackMutation(guiaEditada)
        refresPage()
        onToggle()
      }
    },
    enableReinitialize: true
  })

  const handleFile = (e) => {
    setFileImg(e.target.files[0])
  }

  const showTextarea = () => {
    return +formik.values.IdEstado === 4 || +formik.values.IdEstado === 5
  }

  return (
    <Modal {...{ isOpen, onToggle }}>
      <div className="max-w-lg bg-white px-1 py-8 rounded-md">
        <div className="relative">
          <h1 className="text-center text-xl font-medium mb-4">
            Modificar el estado de la entrega <br />
          </h1>
        </div>
        <form
          className="w-full max-w-xl lg:px-4 px-0 mx-auto flex flex-col items-center"
          onSubmit={formik.handleSubmit}
        >
          <p className="mb-3">GUÍA {itemModal?.IdGuiaR}</p>
          <div className="flex flex-col w-full mb-5">
            <label
              htmlFor="IdEstado"
              className="block text-gray-700 text-left text-sm"
            >
              Estado
            </label>
            <Select
              type="text"
              id="IdEstado"
              name="IdEstado"
              autoComplete="off"
              onBlur={formik.handleBlur}
              value={formik.values.IdEstado}
              onChange={formik.handleChange}
              isInvalid={formik.errors.IdEstado && formik.touched.IdEstado}
            >
              {/* {estados.map((estado) => optionEstado(estado)) */}
              {estados.map((estado) => optionEstado(estado))}
            </Select>
            <ErrorMessage
              {...{
                errors: formik.errors,
                touched: formik.touched,
                name: 'IdEstado'
              }}
            />
          </div>

          {+formik.values.IdEstado === 3 && (
            <div className="flex flex-col w-full mb-7">
              <label
                htmlFor="foto"
                className="block text-gray-700 text-left text-sm"
              >
                Foto
              </label>
              <InputText
                required
                id="foto"
                name="foto"
                type="file"
                onBlur={formik.handleBlur}
                onChange={handleFile}
                isInvalid={formik.errors.foto && formik.touched.fecha}
              />
              <ErrorMessage
                {...{
                  errors: formik.errors,
                  touched: formik.touched,
                  name: 'foto'
                }}
              />
            </div>
          )}
          {showTextarea() && (
            <div className="flex flex-col w-full mb-7">
              <label
                htmlFor="Obs"
                className="block text-gray-700 text-left text-sm"
              >
                Observacion
              </label>
              <textarea
                className="bg-gray-200 py-4 px-3 rounded-lg mt-2"
                required
                min="10"
                id="Obs"
                name="Obs"
                type="Obs"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                isInvalid={formik.errors.foto && formik.touched.fecha}
              />
              <ErrorMessage
                {...{
                  errors: formik.errors,
                  touched: formik.touched,
                  name: 'foto'
                }}
              />
            </div>
          )}
          {loading ? <Loader /> : buttonsModalEstado(onToggle)}
        </form>
      </div>
    </Modal>
  )
}

export default ModalEstadoEntrega
