import { gql } from '@apollo/client'

export const GET_DESTINOS_CLIENTES = gql`
  query GetDestinosClientes($IdCliente: Int) {
    GetDestinosClientes(IdCliente: $IdCliente) {
      IdDestino
      IdCliente
      Destino
    }
  }
`
