import React from 'react'
import { ChevronLeft } from 'react-feather'
// import InputText from '@/components/InputText'
import TableGuias from '@/components/TableDespachos'
import { useHistory, useParams } from 'react-router'

// API
import { useQuery } from '@apollo/client'
import { GET_LISTA_GUIAS } from 'src/graphql/querys/getListaDeGuias'
import Pagination from 'src/components/paginacion/Pagination'
import Loader from 'src/components/Loader/Loader'

const MensajeNoHayGuias = () => (
  <p className="text-xl mt-8 text-center text-gray-400">
    No hay guías en este servicio
  </p>
)

const ListaDespachos = () => {
  // Manejo de rutas
  const { id } = useParams()
  const history = useHistory()

  // Manejo de API
  const { data, loading, refetch } = useQuery(GET_LISTA_GUIAS, {
    fetchPolicy: 'network-only',
    variables: {
      IdServicio: id
    },
    onError: (err) => {
      const error = err?.graphQLErrors[0]?.debugMessage
      if (error) {
        console.log(error)
      } else {
        console.log('error desconocido')
      }
    }
  })

  const listaDeGuias = data ? data.GetListaGuias : []

  const tableProps = {
    listaDeGuias: listaDeGuias,
    refresPage: () =>
      refetch({
        IdServicio: id
      })
  }

  return (
    <div className="md:shadow md:rounded bg-white p-5 py-10 md:p-10">
      <h1 className="flex items-center text-xl md:text-2xl font-regular tracking-wide mb-5">
        <span
          onClick={() => history.push('/lista-servicios')}
          className="p-1 mr-3 rounded-full border border-blue-600 text-blue-600 cursor-pointer"
        >
          <ChevronLeft className="w-5 h-5" />
        </span>
        LISTADO DE GUÍAS DEL SERVICIO {id}
      </h1>
      {/* HEADER */}
      <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-5">
        <div className="w-full md:max-w-max flex items-center mb-3">
          {/*  <InputText type="text" placeholder="Buscar..." />
           <button className="btn-primary mt-2 ml-2 p-3">
             <Search className="text-white" />
           </button> */}
        </div>

        <button
          onClick={() => history.push('/crear-guia', id)}
          className="w-full md:max-w-max btn-primary"
        >
          <span className="text-sm">Nuevo guía</span>
        </button>
      </div>

      {/* TABLA */}
      <div className="overflow-x-auto w-full">
        {loading ? <Loader /> : <TableGuias {...tableProps} />}
      </div>

      {!loading && (
        <div>
          {listaDeGuias.length > 0 ? <Pagination /> : <MensajeNoHayGuias />}
        </div>
      )}
    </div>
  )
}

export default ListaDespachos
