import React from 'react'
// import { useFormik } from 'formik'
// import InputText from '@/components/InputText'
// import ErrorMessage from '@/components/ErrorMessage'
import { ChevronLeft } from 'react-feather'
// import Select from '@/components/Select'
import { useHistory } from 'react-router'
import FormularioGuia from 'src/components/formularioGuia/FormularioGuia'

// APOLLO
import { useMutation } from '@apollo/client'
import { CREATE_GUIA_NUEVA_MUTATION } from '../../graphql/mutations/createGuiaNueva_mutation'
import Loader from 'src/components/Loader/Loader'

const NuevoDespacho = () => {
  const history = useHistory()
  const idServicioActual = history.location.state

  const [createNuevaGuiaMutation, { data, loading }] = useMutation(
    CREATE_GUIA_NUEVA_MUTATION,
    {
      onError: (err) => {
        const error = err?.graphQLErrors[0]?.debugMessage
        if (error) {
          console.log(error)
        } else {
          console.log('error desconocido')
        }
      }
    }
  )
  console.log(data)

  // Funcion para crear nueva guia en el formulario
  const createNuevaGuia = async (values) => {
    console.log('crear nueva guia  : ', values)
    const response = await createNuevaGuiaMutation({
      variables: {
        input: {
          ...values,
          Coordenadas: ''
        }
      }
    })
    const idGuiaCreada = response.data.createGuiasCliente.IdGuiaR
    if (idGuiaCreada) {
      history.push(`/lista-despacho/${idServicioActual}`)
    } else {
      console.log('Error al crear la guía')
    }
  }

  const formValues = {
    Fecha: new Date().toISOString().split('T')[0],
    IdServicio: idServicioActual
  }

  const propsFormulario = {
    mutationApi: createNuevaGuia,
    loadingMutationGuia: loading,
    formValues: formValues
  }

  return (
    <div className="md:shadow md:rounded bg-white p-10">
      <h1 className="flex items-center text-xl md:text-2xl font-regular tracking-wide mb-5">
        <span
          onClick={() => history.goBack()}
          className="p-1 mr-3 rounded-full border border-blue-600 text-blue-600 cursor-pointer"
        >
          <ChevronLeft className="w-5 h-5" />
        </span>
        NUEVO DESPACHO
      </h1>
      <div className="flex justify-center">
        {loading ? <Loader /> : <FormularioGuia {...propsFormulario} />}
      </div>
    </div>
  )
}

export default NuevoDespacho
