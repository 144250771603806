import { gql } from '@apollo/client'

export const GET__CLIENTES = gql`
  query GetClientes($RazonSocial: String) {
    GetClienteNombre(RazonSocial: $RazonSocial) {
      IdCliente
      RazonSocial
    }
  }
`
