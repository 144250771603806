// query getAllEmpresas{
//   GetAllEmpresas{
//     IdEmpresa
//     RUC
//     NmEmpresa
//   }
// }
import { gql } from '@apollo/client'

export const GET__ALL__EMPRESAS = gql`
  query GetAllEmpresas {
    GetAllEmpresas {
      IdEmpresa
      RUC
      NmEmpresa
    }
  }
`
