import React, { useState, useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { ChevronLeft } from 'react-feather'
import { useHistory } from 'react-router'
import InputText from 'src/components/InputText'
import Select from 'src/components/Select'
import { GET_DESTINOS_CLIENTES } from 'src/graphql/querys/getDestinosClientes'
import Mapa from 'src/components/Mapa/Mapa'
import ModalImagen from 'src/components/modalImagen/ModalImagen'

const LeerGuia = () => {
  const history = useHistory()
  // const { id } = useParams()
  const guia = history.location.state
  const [destinoName, setDestinoName] = useState('')

  const [openModal, setOpenModal] = useState(false)
  const onToggle = () => {
    console.log('open modal')
    setOpenModal((prevState) => !prevState)
  }

  // Consumir Destinos
  const { data, loading } = useQuery(GET_DESTINOS_CLIENTES, {
    fetchPolicy: 'network-only',
    variables: {
      IdCliente: guia?.IdCliente
    },
    onError: (err) => {
      const error = err?.graphQLErrors[0]?.debugMessage
      if (error) {
        console.log(error)
      } else {
        console.log('error desconocido')
      }
    }
  })

  const destinos = data ? data?.GetDestinosClientes : []

  useEffect(() => {
    if (destinos?.length > 0) {
      const destinoGuia = destinos.find(
        (destino) => +destino?.IdDestino === +guia?.IdDestino
      )
      setDestinoName(destinoGuia?.Destino)
    }
  }, [loading])

  console.log('coordenadas leer guia :', guia.Coordenadas)

  return (
    <>
      <ModalImagen
        isOpen={openModal}
        onToggle={onToggle}
        imagen={guia.ImagenGuia}
      />
      <div className="md:shadow md:rounded bg-white p-10">
        <div className="flex justify-between">
          <h1 className="flex items-center text-xl md:text-2xl font-regular tracking-wide mb-5">
            <span
              onClick={() => history.goBack()}
              className="p-1 mr-3 rounded-full border border-blue-600 text-blue-600 cursor-pointer"
            >
              <ChevronLeft className="w-5 h-5" />
            </span>
            LEER GUIA {guia?.IdGuiaR}
          </h1>
          <button onClick={onToggle} className="btn-primary">
            Ver imagen
          </button>
        </div>

        <form className="w-full max-w-xl lg:px-4 px-0 mx-auto">
          <div className="flex flex-col lg:flex-row lg:space-x-4 mb-3">
            <div className="flex flex-col w-full mb-4 lg:mb-0">
              <label
                htmlFor="Cliente"
                className="block text-gray-700 text-left text-sm"
              >
                Cliente
              </label>

              <div className="relative">
                <InputText
                  readOnly
                  id="Cliente"
                  name="Cliente"
                  type="text"
                  value={guia?.Cliente}
                />
              </div>
            </div>

            <div className="flex flex-col w-full">
              <label
                htmlFor="FechaEntrega"
                className="block text-gray-700 text-left text-sm"
              >
                Fecha De Entrega
              </label>
              <InputText
                readOnly
                required
                id="FechaEntrega"
                name="FechaEntrega"
                type="date"
                value={guia?.FechaEntrega.split(' ')[0]}
              />
            </div>
          </div>

          <div className="flex flex-col lg:flex-row lg:space-x-4 mb-3">
            <div className="flex flex-col w-full mb-4 lg:mb-0">
              <label className="block text-gray-700 text-left text-sm">
                Cantidad de bultos
              </label>
              <InputText
                readOnly
                required
                id="Bultos"
                name="Bultos"
                type="number"
                value={guia?.Bultos}
              />
            </div>
            <div className="flex flex-col w-full">
              <label
                htmlFor="Peso"
                className="block text-gray-700 text-left text-sm"
              >
                Peso
              </label>
              <InputText
                readOnly
                required
                id="Peso"
                name="Peso"
                type="number"
                value={guia?.Peso}
              />
            </div>
          </div>

          <div className="flex flex-col lg:flex-row lg:space-x-4 items-center mb-3">
            <div className="flex flex-col w-full mb-4 lg:mb-0">
              <label
                htmlFor="Serie"
                className="block text-gray-700 text-left text-sm"
              >
                Serie
              </label>
              <InputText
                readOnly
                required
                id="Serie"
                name="Serie"
                type="text"
                value={guia?.Serie}
              />
            </div>
            <div className="flex flex-col w-full">
              <label
                htmlFor="Numero"
                className="block text-gray-700 text-left text-sm"
              >
                Número
              </label>
              <InputText
                readOnly
                id="Numero"
                name="Numero"
                type="number"
                value={guia?.Numero}
              />
            </div>
          </div>

          <div className="flex flex-col lg:flex-row lg:space-x-4 items-center mb-3">
            <div className="flex flex-col w-full mb-4 lg:mb-0">
              <label
                htmlFor="SerieGT"
                className="block text-gray-700 text-left text-sm"
              >
                Serie GT
              </label>
              <InputText
                readOnly
                required
                id="SerieGT"
                name="SerieGT"
                type="text"
                value={guia?.SerieGT}
                placeholder="Ingresa SerieGT"
              />
            </div>
            <div className="flex flex-col w-full">
              <label
                htmlFor="NumeroGT"
                className="block text-gray-700 text-left text-sm"
              >
                Número GT
              </label>
              <InputText
                readOnly
                required
                id="NumeroGT"
                name="NumeroGT"
                type="number"
                value={guia?.NumeroGT}
              />
            </div>
          </div>

          <div className="flex flex-col lg:flex-row lg:space-x-4 items-center mb-3">
            <div className="flex flex-col w-full mb-4 lg:mb-0">
              <label
                htmlFor="Llegada"
                className="block text-gray-700 text-left text-sm"
              >
                Llegada
              </label>
              <InputText
                readOnly
                id="Llegada"
                name="Llegada"
                type="text"
                value={guia?.Llegada}
              />
            </div>

            <div className="flex flex-col w-full mb-4 lg:mb-0">
              <label
                htmlFor="IdDestino"
                className="block text-gray-700 text-left text-sm"
              >
                Destino
              </label>
              <Select
                readOnly
                id="IdDestino"
                name="IdDestino"
                value={guia?.IdDestino}
              >
                <option defaultValue value={guia?.IdDestino}>
                  {loading ? 'Cargando...' : destinoName}
                </option>
              </Select>
            </div>
          </div>

          <div className="flex flex-col lg:flex-row lg:space-x-4 items-center mb-3">
            <div className="flex flex-col w-full">
              <label
                htmlFor="IdEstado"
                className="block text-gray-700 text-left text-sm"
              >
                Estado
              </label>
              <Select
                readOnly
                type="text"
                id="IdEstado"
                name="IdEstado"
                autoComplete="off"
                value={guia?.IdEstado}
              >
                <option value={guia?.IdEstado}>{guia?.NmEstado}</option>
              </Select>
            </div>
            <div className="flex flex-col w-full"></div>
          </div>
        </form>

        <Mapa coordenadas={guia?.Coordenadas} />
      </div>
    </>
  )
}

export default LeerGuia
