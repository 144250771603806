import { useLazyQuery, useQuery } from '@apollo/client'
import { useFormik } from 'formik'
import React, { useState, useEffect } from 'react'
import InputText from 'src/components/InputText'
import Loader from 'src/components/Loader/Loader'
// import { useQuery } from '@apollo/client'
import useAuth from '../../hooks/useAuth'
import Pagination from 'src/components/paginacion/Pagination'
import Select from 'src/components/Select'
import TableGuiasClient from 'src/components/TableGuiasClient/TableGuiasClient'
import { GET_BUSQUEDA_AVANZADA } from 'src/graphql/querys/getBusquedaAvanzada'
import { GET_ESTADOS } from 'src/graphql/querys/getEstados'
// import useAuth from 'src/hooks/useAuth'
import { useHistory } from 'react-router'

const optionEstado = (objEstado) => (
  <option key={objEstado.IdEstado} value={objEstado.IdEstado}>
    {objEstado.NmEstado}
  </option>
)

const MensajeSinResultados = () => (
  <p className="text-lg text-gray-500">Sin resultados</p>
)

// COMPONENTE PRINCIPAL

const ListaGuiasClient = () => {
  const {
    user: { IdCliente }
  } = useAuth()
  const history = useHistory()
  const stateHistory = history?.location?.state

  const [isSearch, setIsSearch] = useState(false)

  const [paginaActual, setPaginaActual] = useState(1)

  const [guia, setGuia] = useState({
    Numero: '',
    Fecha: '',
    Placa1: '',
    IdEstado: ''
  })

  const loading = true

  // const nroPaginas = Math.ceil(data?.GetListaServicos?.nroTotal_items / 157)
  const nroPaginas = 10

  // Consumir estados generales
  const { data } = useQuery(GET_ESTADOS, {
    variables: {},
    onError: (err) => {
      const error = err?.graphQLErrors[0]?.debugMessage
      if (error) {
        console.log(error)
      } else {
        console.log('error desconocido')
      }
    }
  })
  const estados = data ? data.GetEstados : []

  const [getBusquedaAvanzada, responseBusquedaAvanzada] = useLazyQuery(
    GET_BUSQUEDA_AVANZADA,
    {
      fetchPolicy: 'network-only'
    }
  )

  const initialValues = {
    Numero: guia.Numero,
    Fecha: guia.Fecha.split(' ')[0],
    Placa1: guia.Placa1,
    IdEstado: guia.IdEstado
  }
  const idEmpresa = parseInt(localStorage.getItem('idEmpresa'))
  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: (values) => {
      setGuia({ ...values })
      setIsSearch(true)
      console.log('Buscanco: ', values)
      getBusquedaAvanzada({
        variables: {
          number_paginate: 12,
          page: 1,
          IdCliente: IdCliente,
          Numero: values.Numero,
          Fecha: values.Fecha,
          Placa1: values.Placa1,
          IdEstado: values.IdEstado,
          IdEmpresa: idEmpresa
        }
      })
    }
  })
  // console.log('Id cliente : ', IdCliente)
  const lista = responseBusquedaAvanzada?.data
    ? responseBusquedaAvanzada?.data.GetBusquedaAvanzadaListaGuias.data
    : []

  const tableProps = {
    listaDeGuiasClient: lista
  }

  const condicionSinResultados = () =>
    isSearch && lista.length === 0 && responseBusquedaAvanzada.loading === false

  const condicionTabla = () => lista.length > 0 && isSearch === true

  useEffect(() => {
    if (stateHistory) {
      console.log('state history 2: ', stateHistory)
      setGuia({
        Numero: stateHistory.Numero,
        Fecha: stateHistory.Fecha,
        Placa1: stateHistory.Placa1,
        IdEstado: stateHistory.IdEstado
      })
      getBusquedaAvanzada({
        variables: {
          number_paginate: 12,
          page: 1,
          IdCliente: 377,
          Numero: formik.values.Numero,
          Fecha: formik.values.Fecha,
          Placa1: formik.values.Placa1,
          IdEstado: formik.values.IdEstado
        }
      })
    }
  }, [stateHistory])

  return (
    <div className="md:shadow md:rounded bg-white p-5 py-10 md:p-10">
      <h1 className="flex items-center text-xl md:text-2xl font-regular tracking-wide mb-5">
        BUSQUEDA DE GUÍAS
      </h1>

      {/* // BUSQUEDA */}
      <form onSubmit={formik.handleSubmit} className="mb-10">
        <div className="flex flex-col lg:flex-row lg:space-x-4 mb-3">
          <div className="flex flex-col w-full mb-4 lg:mb-0">
            <label
              htmlFor="Numero"
              className="block text-gray-700 text-left text-sm"
            >
              Numero
            </label>
            <InputText
              onBlur={formik.handleBlur}
              value={formik.values.Numero}
              onChange={formik.handleChange}
              placeholder="Número"
              id="Numero"
              name="Numero"
            />
          </div>
          <div className="flex flex-col w-full mb-4 lg:mb-0">
            <label
              htmlFor="Placa1"
              className="block text-gray-700 text-left text-sm"
            >
              Placa
            </label>
            <InputText
              id="Placa1"
              name="Placa1"
              onBlur={formik.handleBlur}
              value={formik.values.Placa1}
              onChange={formik.handleChange}
              placeholder="Placa"
            />
          </div>
        </div>
        <div className="flex flex-col lg:flex-row lg:space-x-4 mb-3">
          <div className="flex flex-col w-full mb-4 lg:mb-0">
            <label
              htmlFor="IdEstado"
              className="block text-gray-700 text-left text-sm"
            >
              Estado
            </label>
            <Select
              type="text"
              id="IdEstado"
              name="IdEstado"
              autoComplete="off"
              onBlur={formik.handleBlur}
              value={formik.values.IdEstado}
              onChange={formik.handleChange}
              // isinvalid={formik.errors.IdEstado && formik.touched.IdEstado}
            >
              {/* {estados.map((estado) => optionEstado(estado)) */}
              <option value="">Buscar estado</option>
              {estados.map((estado) => optionEstado(estado))}
            </Select>
          </div>
          <div className="flex flex-col w-full mb-4 lg:mb-0">
            <label
              htmlFor="Fecha"
              className="block text-gray-700 text-left text-sm"
            >
              Fecha
            </label>
            <InputText
              id="Fecha"
              name="Fecha"
              type="date"
              onBlur={formik.handleBlur}
              value={formik.values.Fecha}
              onChange={formik.handleChange}
              placeholder="Fecha"
              isinvalid={(
                formik.errors.Fecha && formik.touched.Fecha
              )?.toString()}
            />
          </div>
        </div>
        <div className="flex justify-end gap-4">
          <button
            onClick={() => {
              setGuia({ Numero: '', Fecha: '', Placa1: '', IdEstado: '' })
              setIsSearch(false)
              history.replace('/lista-servicios-client')
            }}
            type="reset"
            className="w-full md:max-w-max mt-4 btn-outline-primary px-4"
          >
            Limpiar
          </button>
          <button
            type={responseBusquedaAvanzada.loading ? 'button' : 'submit'}
            className="w-full md:max-w-max mt-4 btn-primary px-4"
          >
            Buscar
          </button>
        </div>
      </form>

      {/* TABLA */}
      <div className="overflow-x-auto w-full">
        {responseBusquedaAvanzada.loading === true && <Loader />}
        {condicionSinResultados() ? <MensajeSinResultados /> : null}
        {condicionTabla() ? <TableGuiasClient {...tableProps} /> : null}
      </div>

      {/* paginaActual */}

      {!loading && (
        <Pagination
          nroPaginas={nroPaginas}
          paginaActual={paginaActual}
          setPaginaActual={setPaginaActual}
        />
      )}
    </div>
  )
}

export default ListaGuiasClient
