import { gql } from '@apollo/client'

export const GET_LISTA_GUIAS = gql`
  query GetListaGuias($IdServicio: Int) {
    GetListaGuias(IdServicio: $IdServicio) {
      IdGuiaR
      IdServicio
      IdCliente
      Serie
      Numero
      Fecha
      Cliente
      Destinatario
      Llegada
      Bultos
      Peso
      SerieGT
      NumeroGT
      NmEstado
      FechaEntrega
      Coordenadas
      ImagenGuia
      IdDestino
      IdEstado
    }
  }
`
