import React from 'react'
import { MenuIcon } from 'src/assets/logo/icons'

const Header = ({ isOpen, setIsOpen }) => {
  const onToggle = () => setIsOpen(!isOpen)

  return (
    <div
      onClick={onToggle}
      className="fixed top-0 left-0 h-12 bg-gray-900 w-full text-white flex justify-end items-center px-5 z-10 md:hidden"
    >
      <MenuIcon />
    </div>
  )
}

export default Header
