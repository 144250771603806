import { gql } from '@apollo/client'

export const EDITAR_GUIA_MUTATION = gql`
  mutation updateGuiasCliente($input: GuiasClienteInput!, $ImagenGuia: Upload) {
    updateGuiasCliente(input: $input, ImagenGuia: $ImagenGuia) {
      IdGuiaR
      Serie
      Numero
      Fecha
      Llegada
      Bultos
      Peso
      FechaEntrega
      Coordenadas
      ImagenGuia
      SerieGT
      NumeroGT
      IdServicio
      IdCliente
      IdDestino
      IdEstado
    }
  }
`
