import React, { useState } from 'react'

// terceros
import { useFormik } from 'formik'

// utils
import useAuth from '@/hooks/useAuth'
// import { loginSchema } from '@/validation/loginSchema'

// Logo
import Logo from '@/assets/logo/logo.png'
import ErrorMessage from '@/components/ErrorMessage'
import InputText from '@/components/InputText'

// APOLLO
import { useMutation, useQuery } from '@apollo/client'

import { LOGIN_MUTATION } from '../../graphql/mutations/login_mutation'
import { GET__ALL__EMPRESAS } from 'src/graphql/querys/getAllEmpresas'
import Loader from 'src/components/Loader/Loader'
import Select from 'src/components/Select'
import { loginValidation } from 'src/validation/loginSchema'

const initialValues = {
  email: '',
  password: '',
  IdEmpresa: 0
}
const optionElement = (id, label, onAction = () => {}) => (
  <option onClick={onAction} key={id} value={id}>
    {label}
  </option>
)

// Envio del de la empresa al localStorage
console.log(initialValues.IdEmpresa)
// localStorage.setItem('idEmpresa', initialValues.IdEmpresa)
//
const ButtonLogin = () => (
  <button type="submit" className="w-full mt-4 btn-primary">
    Ingresar
  </button>
)

const Login = () => {
  const [mensajeError, setMensajeError] = useState(null)
  // ====== Consumir empresas ======
  const empresasData = useQuery(GET__ALL__EMPRESAS, {
    variables: {},
    onError: (err) => {
      const error = err?.graphQLErrors[0]?.debugMessage
      if (error) {
        console.log(error)
      } else {
        console.log('error desconocido')
      }
    }
  })
  const empresas = empresasData.data ? empresasData.data.GetAllEmpresas : []
  // console.log(empresas)
  ///

  const [loginMutation, { loading }] = useMutation(LOGIN_MUTATION, {
    onError: (err) => {
      const error = err?.graphQLErrors[0]?.debugMessage
      if (error) {
        if (error === 'NO_EXISTE') {
          setMensajeError('Usuario inexistente')
        } else if (error === 'CONTRASEÑA_INCORRECTA') {
          setMensajeError('Contraseña incorrecta')
        }
      } else {
        console.log('error desconocido')
      }
    }
  })

  const { LoginAction } = useAuth()

  const formik = useFormik({
    initialValues,
    // validationSchema: loginSchema,
    validate: loginValidation,
    onSubmit: async (values) => {
      setMensajeError(null)
      const request = await loginMutation({
        variables: {
          input: {
            NmUsuario: values.email,
            Password: values.password
          }
        }
      })
      // Envio del de la empresa al localStorage
      // console.log(formik.values.IdEmpresa)
      // localStorage.setItem('idEmpresa', formik.values.IdEmpresa)
      //
      const apiToken = request.data.login.apiToken
      const user = request.data.login
      if (apiToken) {
        LoginAction(user)
      } else {
        console.log('Error al iniciar sesión')
      }
      // console.log('REQUEST : ', request.data.login.apiToken)
    }
  })
  // Envio del de la empresa al localStorage
  // console.log(formik.values.IdEmpresa)
  localStorage.setItem('idEmpresa', formik.values.IdEmpresa)
  //
  return (
    <section className="min-h-screen flex items-stretch justify-center lg:justify-start sm:bg-gray-100 lg:bg-white">
      <div
        className="lg:flex w-1/2 hidden bg-gray-500 bg-no-repeat bg-cover relative items-center"
        style={{
          backgroundImage:
            'url(https://images.unsplash.com/photo-1577495508048-b635879837f1?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=675&q=80)'
        }}
      >
        <div className="absolute bg-black opacity-60 inset-0 z-0" />
        <div className="w-full px-20 z-10">
          <img src={Logo} alt="Logo Logisticom" className="mb-3" />
          <h1 className="text-5xl text-white font-bold text-left tracking-wide">
            LEXSAC TRACKING
          </h1>
          <p className="text-3xl text-white my-4">
            Bienvenido a nuestro sistema de tracking
          </p>
        </div>
      </div>
      <div className="w-full flex items-center justify-center text-center md:px-16 px-0 z-0 max-w-xl lg:max-w-xl">
        <div className="w-full py-6 z-20 sm:shadow-lg sm:rounded-lg lg:shadow-none lg:rounded-none bg-white">
          <img
            src={Logo}
            alt="Logo Logisticom"
            className="mx-auto mb-8 lg:hidden"
          />
          <h1 className="text-2xl font-bold text-center tracking-wide mb-5">
            INICIAR SESION
          </h1>

          <form
            onSubmit={formik.handleSubmit}
            className="w-full sm:w-2/3 px-4 lg:px-0 mx-auto"
          >
            <div className="flex flex-col">
              <label className="block text-gray-700 text-left text-sm">
                Usuario
              </label>
              <InputText
                id="email"
                type="text"
                name="email"
                autoComplete="off"
                onBlur={formik.handleBlur}
                value={formik.values.email}
                onChange={formik.handleChange}
                placeholder="Ingresa tu usuario"
                isInvalid={formik.errors.email && formik.touched.email}
              />
              <ErrorMessage
                {...{
                  errors: formik.errors,
                  touched: formik.touched,
                  name: 'email'
                }}
              />
            </div>

            <div className="flex flex-col my-4">
              <label className="block text-gray-700 text-left text-sm">
                Contraseña
              </label>
              <InputText
                required
                id="password"
                name="password"
                type="password"
                onBlur={formik.handleBlur}
                value={formik.values.password}
                onChange={formik.handleChange}
                placeholder="Ingresa tu contraseña"
                isInvalid={formik.errors.password && formik.touched.password}
              />
              <ErrorMessage
                {...{
                  errors: formik.errors,
                  touched: formik.touched,
                  name: 'password'
                }}
              />
            </div>
            <div className="flex flex-col w-full mb-4 lg:mb-0">
              <label
                htmlFor="IdEmpresa"
                className="block text-gray-700 text-left text-sm"
              >
                Empresas
              </label>
              <Select
                id="IdEmpresa"
                name="IdEmpresa"
                autoComplete="off"
                onBlur={formik.handleBlur}
                value={parseInt(formik.values.IdEmpresa)}
                onChange={formik.handleChange}
                placeholder="Empresa"
                isinvalid={formik.errors.IdEmpresa && formik.touched.IdEmpresa}
              >
                <option value="0">Seleccione una empresa </option>
                {empresas.map((empresa) =>
                  optionElement(empresa.IdEmpresa, empresa.NmEmpresa)
                )}
              </Select>
              <ErrorMessage
                {...{
                  errors: formik.errors,
                  touched: formik.touched,
                  name: 'IdEmpresa'
                }}
              />
            </div>

            <p className="m-4 text-red-400">{mensajeError}</p>
            {loading ? <Loader size="sm" /> : null}
            {loading ? null : <ButtonLogin />}
          </form>
        </div>
      </div>
    </section>
  )
}

export default Login
