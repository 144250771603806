import React from 'react'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import './styles.css'

import 'leaflet/dist/leaflet.css'
import 'leaflet-defaulticon-compatibility'
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css'

const Mapa = ({ coordenadas }) => {
  console.log('coordenadas map : ', coordenadas)
  const coordenadasDefault = '-12.128536242043475, -76.9887712963079'

  if (coordenadas === undefined || coordenadas === null) {
    coordenadas = coordenadasDefault
  }
  const position = coordenadas?.split(',')
  return (
    <div className="container-map ml-auto mr-auto mt-10">
      <div className="map">
        <MapContainer
          center={position}
          zoom={13}
          scrollWheelZoom={false}
          style={{ height: '100%', width: '100%', zIndex: 1 }}
        >
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker position={position}>
            <Popup>
              A pretty CSS3 popup. <br /> Easily customizable.
            </Popup>
          </Marker>
        </MapContainer>
      </div>
    </div>
  )
}

export default Mapa
