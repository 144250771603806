import React from 'react'

// styles
import styles from './input.module.css'

const InputText = ({ isInvalid = false, ...props }) => {
  return (
    <input
      {...props}
      className={`${styles.input} ${isInvalid ? styles.invalid : ''}`}
    />
  )
}

export default InputText
