import { useLazyQuery, useQuery } from '@apollo/client'
import { useFormik } from 'formik'
import React, { useState, useEffect } from 'react'
import { GET__CLIENTES } from 'src/graphql/querys/getClientes'
import { GET_DESTINOS_CLIENTES } from 'src/graphql/querys/getDestinosClientes'
import { GET_ESTADOS } from 'src/graphql/querys/getEstados'
import { formFillValidation } from 'src/validation/formFillValidation'
import { sleep } from 'src/utils/sleep'
import ErrorMessage from '../ErrorMessage'
import InputText from '../InputText'
import Select from '../Select'
import ListarClientes from './ListarClientes'

const objClienteVacio = {
  RazonSocial: '',
  IdCliente: ''
}

const optionElement = (id, label, onAction = () => {}) => (
  <option onClick={onAction} key={id} value={id}>
    {label}
  </option>
)

// ===== COMPONENTE PRINCIPAL ======
const FormularioGuia = ({
  mutationApi = () => {},
  loadingMutationGuia,
  formValues = { IdCliente: '' },
  nameCliente = ''
}) => {
  const [searchCliente, setSearchCliente] = useState(nameCliente)
  const [clienteSeleccionado, setClienteSeleccionado] =
    useState(objClienteVacio)
  const fechaActual = new Date().toISOString().split('T')[0]

  // handle error
  const [errorMessage, setErrorMessage] = useState('')

  // ====== Consumir clientes ======
  const [getClientesByName, responseClientes] = useLazyQuery(GET__CLIENTES, {
    fetchPolicy: 'network-only',
    variables: {
      RazonSocial: searchCliente
    }
  })
  const loadingGetClientes = responseClientes.loading
  const sugerenciasClientes = responseClientes.data
    ? responseClientes.data.GetClienteNombre
    : []
  const handleChangeInputCliente = async (e) => {
    setSearchCliente(e.target.value)
    if (searchCliente?.trim().length > 2) {
      await sleep(100)
      getClientesByName()
    }
    setErrorMessage('')
    setClienteSeleccionado(objClienteVacio)
  }

  // ====== Consumir destinos ======
  const [getDestinosClientes, responseDestinos] = useLazyQuery(
    GET_DESTINOS_CLIENTES,
    {
      variables: {
        IdCliente: clienteSeleccionado.IdCliente
      }
    }
  )
  const destinosLoading = responseDestinos.loading
  const destinos = responseDestinos.data
    ? responseDestinos.data.GetDestinosClientes
    : []

  // ====== Consumir estados ======
  const estadosData = useQuery(GET_ESTADOS, {
    variables: {},
    onError: (err) => {
      const error = err?.graphQLErrors[0]?.debugMessage
      if (error) {
        console.log(error)
      } else {
        console.log('error desconocido')
      }
    }
  })
  const estados = estadosData.data ? estadosData.data.GetEstados : []

  const seleccionarCliente = (objCliente) => {
    getDestinosClientes()
    setSearchCliente(objCliente.RazonSocial)
    setClienteSeleccionado(objCliente)
    // con cada seleccion de cliente, consumimos sus destinos
  }

  const habilitarLista =
    searchCliente.length > 2 && clienteSeleccionado.RazonSocial === ''

  const formInitialValues = {
    Serie: '',
    Numero: '',
    Fecha: '',
    Llegada: '',
    Bultos: '',
    Peso: '',
    FechaEntrega: '',
    Coordenadas: '',
    SerieGT: '',
    NumeroGT: '',
    IdServicio: '',
    IdDestino: '',
    ...formValues,
    IdEstado: 2,
    IdCliente: clienteSeleccionado.IdCliente
  }

  // const formInitialValuesEdit = {
  //   ...formValues,
  //   IdCliente: clienteSeleccionado.IdCliente
  // }

  // const formInitialValues = () =>
  //   formValues.IdCliente.length === ''
  //     ? formInitialCreate
  //     : formInitialValuesEdit

  const formik = useFormik({
    initialValues: formInitialValues,
    validationSchema: formFillValidation,
    onSubmit: async (values) => mutationApi(values),
    enableReinitialize: true
  })

  const isFomValid = () => {
    if (formik.values.IdCliente === '') {
      setErrorMessage('Seleccionar un cliente valido')
      return false
    } else if (formik.values.IdDestino === '') {
      setErrorMessage('Seleccionar un destino valido')
      return false
    }
    return true
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (isFomValid()) {
      formik.handleSubmit(e)
    }
  }

  useEffect(() => {
    getDestinosClientes()
    if (nameCliente?.length > 0 && nameCliente !== null) {
      setClienteSeleccionado({
        RazonSocial: nameCliente,
        IdCliente: formValues?.IdCliente
      })
    }
  }, [])

  console.log('form values : ', formik.values)

  return (
    <form
      onSubmit={handleSubmit}
      className="w-full max-w-xl lg:px-4 px-0 mx-auto"
    >
      <div className="flex flex-col lg:flex-row lg:space-x-4 mb-3">
        <div className="flex flex-col w-full mb-4 lg:mb-0">
          <label
            htmlFor="Cliente"
            className="block text-gray-700 text-left text-sm"
          >
            Cliente
          </label>

          <div className="relative">
            <InputText
              autoComplete="off"
              required
              id="Cliente"
              name="Cliente"
              type="text"
              onBlur={formik.handleBlur}
              value={searchCliente}
              onChange={handleChangeInputCliente}
              placeholder="Buscar un cliente"
              isinvalid={formik.errors.Cliente && formik.touched.Cliente}
            />

            {habilitarLista && (
              <ListarClientes
                loading={loadingGetClientes}
                sugerenciasClientes={sugerenciasClientes}
                onSelectCliente={seleccionarCliente}
              />
            )}
          </div>

          <ErrorMessage
            {...{
              errors: formik.errors,
              touched: formik.errors,
              name: 'Cliente'
            }}
          />
        </div>

        <div className="flex flex-col w-full">
          <label
            htmlFor="FechaEntrega"
            className="block text-gray-700 text-left text-sm"
          >
            Fecha De Entrega
          </label>
          <InputText
            required
            id="FechaEntrega"
            name="FechaEntrega"
            type="date"
            min={fechaActual}
            onBlur={formik.handleBlur}
            value={formik.values.FechaEntrega}
            onChange={formik.handleChange}
            placeholder="Ingresa La Fecha d"
            isinvalid={(
              formik.errors.FechaEntrega && formik.touched.FechaEntrega
            )?.toString()}
          />
          <ErrorMessage
            {...{
              errors: formik.errors,
              touched: formik.touched,
              name: 'FechaEntrega'
            }}
          />
        </div>
      </div>

      <div className="flex flex-col lg:flex-row lg:space-x-4 mb-3">
        <div className="flex flex-col w-full mb-4 lg:mb-0">
          <label className="block text-gray-700 text-left text-sm">
            Cantidad de bultos
          </label>
          <InputText
            required
            id="Bultos"
            name="Bultos"
            type="number"
            onBlur={formik.handleBlur}
            value={formik.values.Bultos}
            onChange={formik.handleChange}
            placeholder="Ingresa la cantidad de Bultos"
            isinvalid={formik.errors.Bultos && formik.touched.Bultos}
          />
          <ErrorMessage
            {...{
              errors: formik.errors,
              touched: formik.touched,
              name: 'Bultos'
            }}
          />
        </div>
        <div className="flex flex-col w-full">
          <label
            htmlFor="Peso"
            className="block text-gray-700 text-left text-sm"
          >
            Peso
          </label>
          <InputText
            required
            id="Peso"
            name="Peso"
            type="number"
            onBlur={formik.handleBlur}
            value={formik.values.Peso}
            onChange={formik.handleChange}
            placeholder="Ingresa Peso"
            isinvalid={formik.errors.Peso && formik.touched.Peso}
          />
          <ErrorMessage
            {...{
              errors: formik.errors,
              touched: formik.touched,
              name: 'Peso'
            }}
          />
        </div>
      </div>

      <div className="flex flex-col lg:flex-row lg:space-x-4 items-center mb-3">
        <div className="flex flex-col w-full mb-4 lg:mb-0">
          <label
            htmlFor="Serie"
            className="block text-gray-700 text-left text-sm"
          >
            Serie
          </label>
          <InputText
            required
            id="Serie"
            name="Serie"
            type="text"
            onBlur={formik.handleBlur}
            value={formik.values.Serie}
            onChange={formik.handleChange}
            placeholder="Ingresa Serie"
            isinvalid={formik.errors.Serie && formik.touched.Serie}
          />
          <ErrorMessage
            {...{
              errors: formik.errors,
              touched: formik.touched,
              name: 'Serie'
            }}
          />
        </div>
        <div className="flex flex-col w-full">
          <label
            htmlFor="Numero"
            className="block text-gray-700 text-left text-sm"
          >
            Número
          </label>
          <InputText
            required
            id="Numero"
            name="Numero"
            type="number"
            onBlur={formik.handleBlur}
            value={formik.values.Numero}
            onChange={formik.handleChange}
            placeholder="Ingresa Numero"
            isinvalid={formik.errors.Numero && formik.touched.Numero}
          />
          <ErrorMessage
            {...{
              errors: formik.errors,
              touched: formik.touched,
              name: 'Numero'
            }}
          />
        </div>
      </div>

      <div className="flex flex-col lg:flex-row lg:space-x-4 items-center mb-3">
        <div className="flex flex-col w-full mb-4 lg:mb-0">
          <label
            htmlFor="SerieGT"
            className="block text-gray-700 text-left text-sm"
          >
            Serie GT
          </label>
          <InputText
            required
            id="SerieGT"
            name="SerieGT"
            type="text"
            onBlur={formik.handleBlur}
            value={formik.values.SerieGT}
            onChange={formik.handleChange}
            placeholder="Ingresa SerieGT"
            isinvalid={formik.errors.SerieGT && formik.touched.SerieGT}
          />
          <ErrorMessage
            {...{
              errors: formik.errors,
              touched: formik.touched,
              name: 'SerieGT'
            }}
          />
        </div>
        <div className="flex flex-col w-full">
          <label
            htmlFor="NumeroGT"
            className="block text-gray-700 text-left text-sm"
          >
            Número GT
          </label>
          <InputText
            required
            id="NumeroGT"
            name="NumeroGT"
            type="number"
            onBlur={formik.handleBlur}
            value={formik.values.NumeroGT}
            onChange={formik.handleChange}
            placeholder="Ingresa NumeroGT"
            isinvalid={formik.errors.NumeroGT && formik.touched.NumeroGT}
          />
          <ErrorMessage
            {...{
              errors: formik.errors,
              touched: formik.touched,
              name: 'NumeroGT'
            }}
          />
        </div>
      </div>

      <div className="flex flex-col lg:flex-row lg:space-x-4 items-center mb-3">
        <div className="flex flex-col w-full mb-4 lg:mb-0">
          <label
            htmlFor="Llegada"
            className="block text-gray-700 text-left text-sm"
          >
            Llegada
          </label>
          <InputText
            required
            id="Llegada"
            name="Llegada"
            type="text"
            onBlur={formik.handleBlur}
            value={formik.values.Llegada}
            onChange={formik.handleChange}
            placeholder="Ingresa Llegada"
            isinvalid={formik.errors.Llegada && formik.touched.Llegada}
          />
          <ErrorMessage
            {...{
              errors: formik.errors,
              touched: formik.touched,
              name: 'llegada'
            }}
          />
        </div>

        <div className="flex flex-col w-full mb-4 lg:mb-0">
          <label
            htmlFor="IdDestino"
            className="block text-gray-700 text-left text-sm"
          >
            Destino
          </label>
          <Select
            id="IdDestino"
            name="IdDestino"
            autoComplete="off"
            onBlur={formik.handleBlur}
            value={formik.values.IdDestino}
            onChange={formik.handleChange}
            placeholder="Destino"
            isinvalid={formik.errors.IdDestino && formik.touched.IdDestino}
          >
            <option defaultValue>
              {destinosLoading ? 'Cargando' : 'Selecciona un destino'}
            </option>
            {destinos.map((destino) =>
              optionElement(destino.IdDestino, destino.Destino)
            )}
          </Select>
          <ErrorMessage
            {...{
              errors: formik.errors,
              touched: formik.touched,
              name: 'IdDestino'
            }}
          />
        </div>
      </div>

      <div className="flex flex-col lg:flex-row lg:space-x-4 items-center mb-3">
        <div className="flex flex-col w-full">
          <label
            htmlFor="IdEstado"
            className="block text-gray-700 text-left text-sm"
          >
            Estado
          </label>
          <Select
            type="text"
            id="IdEstado"
            name="IdEstado"
            autoComplete="off"
            onBlur={formik.handleBlur}
            value={formik.values.IdEstado}
            onChange={formik.handleChange}
            isinvalid={formik.errors.IdEstado && formik.touched.IdEstado}
          >
            {estados.map((estado) =>
              optionElement(estado.IdEstado, estado.NmEstado)
            )}
          </Select>
          <ErrorMessage
            {...{
              errors: formik.errors,
              touched: formik.touched,
              name: 'IdEstado'
            }}
          />
        </div>
        <div className="flex flex-col w-full"></div>
      </div>
      {errorMessage.length > 0 && (
        <p className="border-2 rounded-xl text-red-500 py-2 px-4 border-red-500">
          {errorMessage}
        </p>
      )}

      <button
        type={`${loadingMutationGuia ? 'button' : 'submit'}`}
        className="w-full md:max-w-max mt-4 btn-primary px-4"
      >
        Enviar
      </button>
    </form>
  )
}

export default FormularioGuia
