import React, { useEffect, useState } from 'react'

// terceros
import { Route, Switch, useHistory } from 'react-router-dom'

// components
import Sidebar from '@/components/Sidebar'

// vistas
import Login from '@/views/auth/Login'
import NuevoDespacho from '@/views/driver/NuevoDespacho'
import ListaServicios from '@/views/driver/ListaServicios'
// import ListaDespachos from '@/views/driver/ListaDespachos'
import EditarDespacho from '@/views/driver/EditarDespacho'

// utils
import useAuth from '@/hooks/useAuth'
import ListaDespachos from '@/views/driver/ListaDespachos'
import ListaGuiasClient from 'src/views/client/ListaGuiasClient'
import LeerGuia from 'src/views/driver/LeerGuia'
import LeerGuiaClient from 'src/views/client/LeerGuiaClient'
import Header from 'src/components/header/Header'

// RUTA PARA CHOFERES
const DriverPages = (history) => {
  return (
    <>
      <Route path="/lista-servicios" component={ListaServicios} />
      <Route exact path="/nuevo-despacho" component={NuevoDespacho} />
      <Route exact path="/editar-despacho/:id" component={EditarDespacho} />
      {/* cada item de la lista de servicios llama a una lista de despachos  */}
      <Route exact path="/lista-despacho/:id" component={ListaDespachos} />
      <Route exact path="/leer-guia/:id" component={LeerGuia} />
      <Route exact path="/crear-guia" component={NuevoDespacho} />
    </>
  )
}

// RUTA PARA CLIENTES
const ClientPages = (history) => {
  return (
    <>
      <Route path="/lista-servicios-client" component={ListaGuiasClient} />
      <Route exact path="/leer-guia-client/:id" component={LeerGuiaClient} />
    </>
  )
}

// RUTA PARA AUTHENTICACION
const AuthPages = () => {
  return <Route exact path="/auth/login" component={Login} />
}

const RootRouter = () => {
  const {
    location: { pathname }
  } = useHistory()

  const {
    user: { isAuth }
  } = useAuth()
  const {
    user: { IdPerfil }
  } = useAuth()
  const history = useHistory()

  useEffect(() => {
    // para cada recarga validamos la autenticacion
    if (isAuth) {
      // si recien se loguea, redirigimos a la vista inicial del perfil correspondiente
      if (pathname === '/auth/login') {
        if (IdPerfil === 2) {
          history.push('/lista-servicios')
        } else {
          console.log('rutas cliente')
          history.push('/lista-servicios-client')
        }
      }
    } else {
      history.push('/auth/login')
    }
  }, [isAuth])

  const routerPerfil = (history) =>
    +IdPerfil === 2 ? DriverPages(history) : ClientPages(history)

  const [isOpen, setIsOpen] = useState(false)

  return (
    <div>
      {isAuth ? <Header isOpen={isOpen} setIsOpen={setIsOpen} /> : null}
      {isAuth ? <Sidebar isOpen={isOpen} setIsOpen={setIsOpen} /> : null}
      <div
        className={
          isAuth ? 'min-h-screen md:ml-64 bg-white md:bg-gray-200' : null
        }
      >
        <div
          onClick={isOpen === true ? () => setIsOpen(false) : null}
          className={
            isAuth ? 'max-w-7xl mt-12 md:mt-0 md:w-11/12 mx-auto md:py-8' : null
          }
        >
          <Switch>{isAuth ? routerPerfil(history) : AuthPages(history)}</Switch>
        </div>
      </div>
    </div>
  )
}

export default RootRouter
