import React from 'react'
import Modal from '../modal/Modal'

// Botones del modal
// const buttonsModal = (onToggle, onAction) => (
//   <div className="flex justify-around">
//     <button
//       type="button"
//       onClick={onToggle}
//       className="w-full md:max-w-max mt-4 btn-outline-primary px-4"
//     >
//       Cerr
//     </button>
//     <button
//       onClick={onAction}
//       type="submit"
//       className="w-full md:max-w-max mt-4 btn-warning px-4"
//     >
//       Eliminar
//     </button>
//   </div>
// )

const ModalImagen = ({ isOpen = false, onToggle = () => {}, imagen }) => {
  console.log('IMAGEN, ', imagen)
  return (
    <Modal {...{ isOpen, onToggle }}>
      <div className="max-w-lg bg-white px-1 py-8 rounded-md">
        <div className="relative">
          <h1 className="text-center text-xl font-medium mb-4">
            Imagen de la entrega
            <br />
          </h1>
        </div>
        <div className="mx-auto">
          {!imagen ? <p className="text-md">Sin imagen</p> : null}
          {imagen.length > 0 ? <img src={imagen} /> : null}
        </div>
        {/* {loading ? <Loader /> : buttonsModal(onToggle, handleConfirmDelete)} */}
        <div className="flex justify-center">
          <button
            type="button"
            onClick={onToggle}
            className="w-full md:max-w-max mt-4 btn-outline-primary px-4"
          >
            Cerrar
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default ModalImagen
