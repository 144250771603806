import { gql } from '@apollo/client'

export const CREATE_GUIA_TRACK_MUTATION = gql`
  mutation createGuiasTrack($input: GuiasTrackInput!) {
    createGuiasTrack(input: $input) {
      IdGuiaR
      FechaHora
      Coordenadas
      Obs
      IdEstado
    }
  }
`
