// import React, { useState } from 'react'
import { BookOpen } from 'react-feather'
import ItemEstado from '../itemEstado/ItemEstado'
import { useHistory } from 'react-router'

const TableGuiasClient = ({ listaDeGuiasClient }) => {
  console.log('GUIAS ===> ', listaDeGuiasClient)
  const history = useHistory()
  return (
    <>
      <table className="min-w-max w-full table-auto ">
        <thead>
          <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
            <th className="py-3 pl-3 text-left">Id Guia</th>
            <th className="py-3 px-6 text-left">Fecha</th>
            <th className="py-3 px-3 text-center">Cliente</th>
            <th className="py-3 px-3 text-center">Numero</th>
            <th className="py-3 px-3 text-center">Llegada</th>
            <th className="py-3 px-6 text-center">Estado</th>
            <th className="py-3 px-6 text-center">Acciones</th>
          </tr>
        </thead>
        <tbody className="text-gray-600 text-sm font-light">
          {listaDeGuiasClient.map((guia) => {
            return (
              <tr
                key={guia.IdGuiaR}
                className="border-b border-gray-200 hover:bg-gray-100"
              >
                <td className="py-3 pl-3 text-left whitespace-nowrap">
                  <div className="flex items-center">
                    <span className="font-medium">{guia.IdGuiaR}</span>
                  </div>
                </td>
                <td className="py-3 px-6 text-left">
                  <div className="flex items-center">
                    <span>{guia.Fecha}</span>
                  </div>
                </td>
                <td className="py-3 text-center">
                  <span>{guia.Cliente}</span>
                </td>
                <td className="py-3 text-center">
                  <span>{guia.Numero}</span>
                </td>
                <td className="py-3 px-3 text-center">
                  <span>{guia.Llegada}</span>
                </td>
                <td className="py-3 px-6 text-center">
                  <span className="flex gap-3">
                    <ItemEstado label={guia.NmEstado} type={guia.NmEstado} />
                  </span>
                </td>
                <td className="py-3 px-6 text-center">
                  <span
                    onClick={() => {
                      console.log('guia : ', guia)
                      history.push(`/leer-guia-client/${guia.IdGuiaR}`, guia)
                    }}
                  >
                    <BookOpen className="w-5 h-5 cursor-pointer transform hover:scale-105" />
                  </span>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </>
  )
}

export default TableGuiasClient
