// Root Router
import RootRouter from './routers/RootRouter'

// terceros
import { BrowserRouter as Router } from 'react-router-dom'

// context
import AuthState from './context/states/AuthState'

const App = () => {
  return (
    <Router>
      <AuthState>
        <RootRouter />
      </AuthState>
    </Router>
  )
}

export default App
