export const getLocation = () => {
  return new Promise((resolve, reject) => {
    if (!navigator.geolocation) {
      throw new Error('Geolocation is not supported by your browser')
    } else {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          resolve({
            lat: position.coords.latitude,
            long: position.coords.longitude
          })
        },
        ({ code, message }) => {
          //   throw new Error('Unable to retrieve your location')
          throw new Error(`Code : ${code}, Message : ${message}`)
        }
      )
    }
  })
}
