import { LOADING, LOGIN, LOGOUT } from '../types'

export const AuthACtions = (state, dispatch) => {
  const LoginAction = (user) => {
    localStorage.setItem('user', JSON.stringify({ ...user, isAuth: true }))
    localStorage.setItem('token', user.apiToken)
    dispatch({ type: LOGIN, payload: user })
  }

  const LogoutAction = () => {
    localStorage.removeItem('user')
    localStorage.removeItem('token')
    dispatch({ type: LOGOUT })
  }

  const loading = (bool) => {
    dispatch({ type: LOADING, payload: bool })
  }

  return { LoginAction, LogoutAction, loading }
}
