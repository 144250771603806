import React from 'react'

const ListarClientes = ({
  sugerenciasClientes = [],
  onSelectCliente = () => {},
  loading
}) => {
  const mensajeCargando = (loading) => (
    <li className="py-2 px-4 hover:bg-blue-400 hover:text-white">
      Cargando...
    </li>
  )

  const mensajeSinResultados = (loading) => (
    <li className="py-2 px-4 hover:bg-blue-400 hover:text-white">
      Sin resultados
    </li>
  )

  const renderListaClientes = () => {
    return sugerenciasClientes.map((cliente) => (
      <li
        onClick={() => onSelectCliente(cliente)}
        key={cliente.IdCliente}
        className="py-2 px-4 hover:bg-blue-400 hover:text-white"
      >
        {cliente.RazonSocial}
      </li>
    ))
  }

  return (
    <ul className="absolute w-full top-full left-0 bg-white shadow-xl rounded-lg overflow-hidden cursor-pointer">
      {loading === false
        ? sugerenciasClientes.length > 0
          ? renderListaClientes()
          : mensajeSinResultados()
        : mensajeCargando()}
    </ul>
  )
}

export default ListarClientes
