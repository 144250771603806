import React from 'react'
import { useHistory } from 'react-router'
import { EyeIcon } from 'src/assets/logo/icons'
import ItemEstado from '../itemEstado/ItemEstado'

const TableServicios = ({ listaDeServicios }) => {
  const history = useHistory()

  return (
    <table className="min-w-max w-full table-auto ">
      <thead>
        <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
          <th className="py-3 pl-3 text-left">Id Servicio</th>
          <th className="py-3 px-6 text-left">Fecha</th>
          <th className="py-3 px-3 text-center">Tipo</th>
          <th className="py-3 px-3 text-center">Placa1</th>
          <th className="py-3 px-3 text-center">Chofer</th>
          <th className="py-3 px-6 text-center">Nm Estado</th>
          <th></th>
        </tr>
      </thead>
      <tbody className="text-gray-600 text-sm font-light">
        {listaDeServicios.map((servicio) => {
          return (
            <tr
              onClick={() =>
                history.push(`/lista-despacho/${servicio.IdServicio}`)
              }
              key={servicio.IdServicio}
              className="border-b border-gray-200 hover:bg-gray-100 cursor-pointer"
            >
              <td className="py-3 pl-3 text-left whitespace-nowrap">
                <div className="flex items-center">
                  <span className="font-medium">{servicio.IdServicio}</span>
                </div>
              </td>
              <td className="py-3 px-6 text-left">
                <div className="flex items-center">
                  <span>{servicio.Fecha}</span>
                </div>
              </td>
              <td className="py-3 text-center">
                <span>{servicio.Tipo}</span>
              </td>
              <td className="py-3 px-3 text-center">
                <span>{servicio.Placa1}</span>
              </td>
              <td className="py-3 text-center">
                <span>{servicio.Chofer}</span>
              </td>
              <td className="py-3 px-6 text-center">
                <ItemEstado
                  label={servicio.NmEstado}
                  type={servicio.NmEstado}
                />
              </td>
              <td className="py-3 px-6 text-center">
                <EyeIcon width="18" heigth="18" />
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export default TableServicios
