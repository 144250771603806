import React, { createContext, useReducer, useEffect } from 'react'
import { getLocation } from '../../utils/geolocation'

// reducer
import AuthReducer from '../reducers/AuthReducer'

// utils
import { AuthACtions } from '../actions/AuthActions'

export const initialState = {
  user: {
    isAuth: false,
    loading: false,
    IdUsuario: '',
    NmUsuario: '',
    IdPerfil: '',
    IdChofer: '',
    IdCliente: '',
    apiToken: ''
  }
}

export const AuthContext = createContext()

const initialStorage = () => {
  const userStorage = JSON.parse(localStorage.getItem('user'))
  if (userStorage) {
    return {
      ...initialState,
      user: userStorage
    }
  } else {
    return initialState
  }
}

const AuthState = ({ children }) => {
  const [state, dispatch] = useReducer(AuthReducer, initialStorage())

  useEffect(() => {
    getLocation()
  }, [])

  const stateProps = {
    // Estados
    ...state,
    // funciones,
    ...AuthACtions(state, dispatch)
  }

  return (
    <AuthContext.Provider value={stateProps}>{children}</AuthContext.Provider>
  )
}

export default AuthState
