import { initialState } from '../states/AuthState'
import { LOADING, LOGIN, LOGOUT } from '../types'

const AuthReducer = (state = initialState, { type, payload }) => {
  if (type === LOGIN) {
    return {
      ...state,
      user: {
        ...payload,
        isAuth: true
      }
    }
  }

  if (type === LOGOUT) {
    return {
      ...initialState,
      user: {
        isAuth: false,
        loading: false,
        IdUsuario: '',
        NmUsuario: '',
        IdPerfil: '',
        IdChofer: '',
        IdCliente: '',
        apiToken: ''
      }
    }
  }

  if (type === LOADING) {
    return { ...state, loading: payload }
  }

  return state
}

export default AuthReducer
