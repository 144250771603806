import React from 'react'

const Pagination = ({ nroPaginas, paginaActual, setPaginaActual }) => {
  return (
    <div>
      <ul className="flex pl-0 list-none rounded my-2">
        <li
          onClick={() => setPaginaActual((prevState) => prevState - 1)}
          className="relative block py-2 px-3 leading-tight bg-white border border-gray-300 text-blue-700 border-r-0 ml-0 rounded-l hover:bg-gray-200"
        >
          <a className="page-link" href="#">
            Previous
          </a>
        </li>
        <li className="relative block py-2 px-3 leading-tight bg-blue-700 border border-gray-300 text-white border-r-0 hover:bg-gray-200">
          <a className="page-link" href="#">
            {paginaActual}
          </a>
        </li>
        <li
          onClick={() => setPaginaActual((prevState) => prevState + 1)}
          className="relative block py-2 px-3 leading-tight bg-white border border-gray-300 text-blue-700 rounded-r hover:bg-gray-200"
        >
          <a className="page-link" href="#">
            Next
          </a>
        </li>
      </ul>
    </div>
  )
}

export default Pagination

// <div className="mt-5">
//   <ul className="flex pl-0 list-none rounded my-2">
//     <li className="relative block py-2 px-3 leading-tight bg-white border border-gray-300 text-blue-700 border-r-0 ml-0 rounded-l hover:bg-gray-200">
//       <a className="page-link" href="#">
//         Previous
//       </a>
//     </li>
//     <li className="relative block py-2 px-3 leading-tight bg-white border border-gray-300 text-blue-700 border-r-0 hover:bg-gray-200">
//       <a className="page-link" href="#">
//         1
//       </a>
//     </li>
//     <li className="relative block py-2 px-3 leading-tight bg-white border border-gray-300 text-blue-700 border-r-0 hover:bg-gray-200">
//       <a className="page-link" href="#">
//         2
//       </a>
//     </li>
//     <li className="relative block py-2 px-3 leading-tight bg-white border border-gray-300 text-blue-700 border-r-0 hover:bg-gray-200">
//       <a className="page-link" href="#">
//         3
//       </a>
//     </li>
//     <li className="relative block py-2 px-3 leading-tight bg-white border border-gray-300 text-blue-700 border-r-0 hover:bg-gray-200">
//       <a className="page-link" href="#">
//         4
//       </a>
//     </li>
//     <li className="relative block py-2 px-3 leading-tight bg-white border border-gray-300 text-blue-700 border-r-0 hover:bg-gray-200">
//       <a className="page-link" href="#">
//         5
//       </a>
//     </li>
//     <li className="relative block py-2 px-3 leading-tight bg-white border border-gray-300 text-blue-700 border-r-0 hover:bg-gray-200">
//       ...
//     </li>
//     <li className="relative block py-2 px-3 leading-tight bg-white border border-gray-300 text-blue-700 border-r-0 hover:bg-gray-200">
//       de {nroPaginas}
//     </li>
//     <li className="relative block py-2 px-3 leading-tight bg-white border border-gray-300 text-blue-700 rounded-r hover:bg-gray-200">
//       <a className="page-link" href="#">
//         Next
//       </a>
//     </li>
//   </ul>
// </div>
