import { gql } from '@apollo/client'

export const GET_ESTADOS = gql`
  query GetEstados {
    GetEstados {
      IdEstado
      NmEstado
    }
  }
`
