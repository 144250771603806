import React, { useEffect, useState } from 'react'

const ItemEstado = ({ label = 'estado', type }) => {
  const [styles, setStyles] = useState('')

  useEffect(() => {
    const styles = (type) => {
      switch (type) {
        case 'ANULADO':
          return 'bg-red-200 text-red-600'
        case 'FINALIZADO':
          return 'bg-green-200 text-green-600'
        case 'PROGRAMADO':
          return 'bg-blue-200 text-blue-600'
        case 'CARGANDO':
          return 'bg-blue-200 text-blue-600'
        case 'ENTREGADO':
          return 'bg-green-200 text-green-600'
        case 'NO ENTREGADO':
          return 'bg-red-200 text-red-600'
        default:
          return 'bg-gray-200'
      }
    }

    setStyles(styles(type))
  })

  return (
    <span className={`${styles} py-1 px-3 rounded-full text-xs font-semibol`}>
      {label}
    </span>
  )
}

export default ItemEstado
