import React from 'react'
import Modal from '../modal/Modal'
import { useMutation } from '@apollo/client'
import Loader from '../Loader/Loader'
import { DELETE_GUIA_MUTATION } from 'src/graphql/mutations/deleteGuia_mutation'
// import ItemEstado from '../itemEstado/ItemEstado'

// Botones del modal
const buttonsModal = (onToggle, onAction) => (
  <div className="flex justify-around">
    <button
      type="button"
      onClick={onToggle}
      className="w-full md:max-w-max mt-4 btn-outline-primary px-4"
    >
      Cancelar
    </button>
    <button
      onClick={onAction}
      type="submit"
      className="w-full md:max-w-max mt-4 btn-warning px-4"
    >
      Eliminar
    </button>
  </div>
)

const ModalDeleteGuia = ({
  isOpen = false,
  onToggle = () => {},
  itemModal,
  refresPage
}) => {
  // ELIMINAR GUIA MUTATION
  const [deleteGuiaMutation, { loading }] = useMutation(DELETE_GUIA_MUTATION, {
    onError: (err) => {
      const error = err?.graphQLErrors[0]?.debugMessage
      if (error) {
        console.log(error)
      } else {
        console.log('error desconocido')
      }
    }
  })

  const handleConfirmDelete = async () => {
    const response = await deleteGuiaMutation({
      variables: {
        input: {
          IdGuiaR: itemModal?.IdGuiaR
        }
      }
    })
    console.log('response delete guia : ', response)
    // if (guiaEditada) {
    //     console.log('guia creada con exito: ', guiaEditada)
    //     refresPage()
    //     onToggle()
    // }
  }

  return (
    <Modal {...{ isOpen, onToggle }}>
      <div className="max-w-lg bg-white px-1 py-8 rounded-md">
        <div className="relative">
          <h1 className="text-center text-xl font-medium mb-4">
            Desea eliminar la guía {itemModal?.IdGuiaR} <br />
          </h1>
        </div>
        {loading ? <Loader /> : buttonsModal(onToggle, handleConfirmDelete)}
      </div>
    </Modal>
  )
}

export default ModalDeleteGuia
