import { gql } from '@apollo/client'

export const GET_LISTA_SERVICIOS = gql`
  query GetListaServicos(
    $IdChofer: String
    $page: Int
    $number_paginate: Int
    $IdEmpresa: Int
  ) {
    GetListaServicos(
      IdChofer: $IdChofer
      page: $page
      number_paginate: $number_paginate
      IdEmpresa: $IdEmpresa
    ) {
      nroTotal_items
      data {
        IdServicio
        Fecha
        Tipo
        Placa1
        Chofer
        NmEstado
        IdPlaca1
        IdChofer
        IdEstado
      }
    }
  }
`
